import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as ControlBg } from '../../assets/images/Control/control-bg.svg'
import { ReactComponent as ControlBgTb } from '../../assets/images/Control/control-bg-tb.svg'
import { ReactComponent as ControlBgTp } from '../../assets/images/Control/control-bg-tp.svg'
import { ReactComponent as ControlBgMb } from '../../assets/images/Control/control-bg-mb.svg'
import { ReactComponent as Play } from '../../assets/images/Control/play.svg'
import { ReactComponent as Stop } from '../../assets/images/Control/stop.svg'

const Control = ({ isPlaying }) => {
  const isTb = useMediaQuery({ query: '(max-width: 1919px)' })
  const isTp = useMediaQuery({ query: '(max-width: 1279px)' })
  const isMb = useMediaQuery({ query: '(max-width: 767px)' })
  let bg = isMb ? (
    <ControlBgMb />
  ) : isTp ? (
    <ControlBgTp />
  ) : isTb ? (
    <ControlBgTb />
  ) : (
    <ControlBg />
  )
  return (
    <div className={`sound-control ${isPlaying ? 'stop' : 'play'}`}>
      <div className="sound-control__line">
        {!isPlaying ? (
          <>
            <Play />
            <span>Слушать</span>
          </>
        ) : (
          <>
            <Stop />
            <span>Остановить</span>
          </>
        )}
      </div>
      {bg}
    </div>
  )
}

export default Control
