import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import './Team.scss'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import Img from '../../assets/images/team/pic.png'
import Share from '../../components/Share/Share'

const Team = ({ difficulty, setDifficulty, setKey }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 1279px)' })

  return (
    <div className="main-wrap main-wrap__white">
      {isMobile ? (
        <Header
          showDifficulty={false}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          link={'read'}
          isModalStory={false}
          setKey={setKey}
        />
      ) : (
        <Header
          showDifficulty={false}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          link={'read'}
          isModalStory={false}
          setKey={setKey}
        />
      )}
      <div className="content">
        <div className="container">
          <div className="team">
            <div className="team__text-block">
              <div className="team__text info-text">
                <div className="team__title info-text__title">Команда</div>
                <div className="team__description info-text__description">
                  <div className="team__list">
                    <div className="team__column">
                      <div>
                        <div>Оля Пархоменко — редактор</div>
                        <div>Ксюша Осколкова — креатор</div>
                        <div>Ирина Михеева — корректор</div>
                      </div>
                      <div>
                        <div>Маша Климентьева — ИТ-менеджер</div>
                        <div>Лёша Стасенко — разработчик</div>
                      </div>
                      <div>
                        <div>Света Бондаренко — эксперт по доступности</div>
                      </div>
                    </div>
                    <div className="team__column">
                      <div>
                        <div>Тася Козлова — иллюстратор</div>
                        <div>Катя Свиридова — арт-директор</div>
                        <div>Галя Горбунова — ведущий дизайнер</div>
                        <div>Кристина Абаринова — дизайнер-стажёр</div>
                      </div>
                      <div>
                        <div>Наташа Габис — моушен-дизайнер</div>
                        <div>Артём Лукьянов — моушен-дизайнер</div>
                      </div>
                    </div>
                  </div>

                  <Share />
                </div>
              </div>

              <div className="team-img">
                <img src={Img} alt="Очень важная История" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        linkLeft={{ text: 'Назад', link: '/reviews' }}
        linkRight={{ text: 'Дальше', link: '' }}
        currentStep={3}
        maxStep={3}
      />
    </div>
  )
}

export default Team
