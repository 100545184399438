import React from 'react'
import Control from '../../components/Control/Control'

const Visual = ({ isPlaying, pause, play, setIsPlaying, sound }) => {
  return (
    <div
      className="sound"
      onClick={() => {
        if (isPlaying) {
          pause()
        } else {
          play()
        }
        setIsPlaying(!isPlaying)
      }}
    >
      {sound}
      <Control isPlaying={isPlaying} />
    </div>
  )
}

export default Visual
