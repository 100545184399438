import ReviewPhoto from '../../assets/images/reviews/photo/review-1.png'
const reviews = [
  {
    name: 'Анна Котельникова',
    excerpt:
      'Собрали отзывы пользователей с&nbsp;различными ограничениями, чтобы показать их&nbsp;опыт взаимодействия с&nbsp;диджитал продуктами.',
    text: '<p>Жизнь с&nbsp;ограничениями по&nbsp;зрению&nbsp;&mdash; это постоянное преодоление, но&nbsp;и&nbsp;особый способ видеть мир. Первое, что приходит на&nbsp;ум, это благодарность за&nbsp;технологии и&nbsp;людей, которые помогают преодолевать трудности. Специальные приложения на&nbsp;смартфоне, экранные читалки и&nbsp;голосовые помощники стали для меня настоящими друзьями и&nbsp;проводниками в&nbsp;мире информации. </p> <p>Конечно, есть и&nbsp;трудности. Иногда сталкиваешься с&nbsp;непониманием, когда инфраструктура в&nbsp;городе не&nbsp;адаптирована&nbsp;&mdash; например, отсутствие звуковых сигналов на&nbsp;светофорах или неадаптированные веб-сайты. Но, несмотря на&nbsp;это, я&nbsp;чувствую поддержку от&nbsp;общества. Люди часто предлагают помощь, и&nbsp;это очень ценно.</p>',
    photo: ReviewPhoto,
  },
  {
    name: 'qwer',
    excerpt:
      'Собрали отзывы пользователей с&nbsp;различными ограничениями, чтобы показать их опыт взаимодействия с&nbsp;диджитал продуктами.',
    text: '<p>Жизнь с&nbsp;ограничениями по&nbsp;зрению&nbsp;&mdash; это постоянное преодоление, но&nbsp;и&nbsp;особый способ видеть мир. Первое, что приходит на&nbsp;ум, это благодарность за&nbsp;технологии и&nbsp;людей, которые помогают преодолевать трудности. Специальные приложения на&nbsp;смартфоне, экранные читалки и&nbsp;голосовые помощники стали для меня настоящими друзьями и&nbsp;проводниками в&nbsp;мире информации. </p> <p>Конечно, есть и&nbsp;трудности. Иногда сталкиваешься с&nbsp;непониманием, когда инфраструктура в&nbsp;городе не&nbsp;адаптирована&nbsp;&mdash; например, отсутствие звуковых сигналов на&nbsp;светофорах или неадаптированные веб-сайты. Но, несмотря на&nbsp;это, я&nbsp;чувствую поддержку от&nbsp;общества. Люди часто предлагают помощь, и&nbsp;это очень ценно.</p>',
    photo: ReviewPhoto,
  },
  {
    name: 'qwer',
    excerpt:
      'Собрали отзывы пользователей с&nbsp;различными ограничениями, чтобы показать их опыт взаимодействия с&nbsp;диджитал продуктами.',
    text: '<p>Жизнь с&nbsp;ограничениями по&nbsp;зрению&nbsp;&mdash; это постоянное преодоление, но&nbsp;и&nbsp;особый способ видеть мир. Первое, что приходит на&nbsp;ум, это благодарность за&nbsp;технологии и&nbsp;людей, которые помогают преодолевать трудности. Специальные приложения на&nbsp;смартфоне, экранные читалки и&nbsp;голосовые помощники стали для меня настоящими друзьями и&nbsp;проводниками в&nbsp;мире информации. </p> <p>Конечно, есть и&nbsp;трудности. Иногда сталкиваешься с&nbsp;непониманием, когда инфраструктура в&nbsp;городе не&nbsp;адаптирована&nbsp;&mdash; например, отсутствие звуковых сигналов на&nbsp;светофорах или неадаптированные веб-сайты. Но, несмотря на&nbsp;это, я&nbsp;чувствую поддержку от&nbsp;общества. Люди часто предлагают помощь, и&nbsp;это очень ценно.</p>',
    photo: ReviewPhoto,
  },
]

export default reviews
